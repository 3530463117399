export default {
	messages: {
		ru: {
			'Настройки приватности': 'Настройки приватности',
			'Разрешить покупателям связываться со мной через чат': 'Разрешить покупателям связываться со мной через чат',
			'Получать рассылки про обновление, акции, конкурсы': 'Получать рассылки про обновление, акции, конкурсы',
			'Управление страницей': 'Управление страницей',
			'Вы можете удалить свою учетную запись. Восстановить доступ к своей странице можно в любой момент.': 'Вы можете удалить свою учетную запись. Восстановить доступ к своей странице можно в любой момент.',
			'Удалить': 'Удалить',
			'Вы действительно хотите удалить свою учетную запись?': 'Вы действительно хотите удалить свою учетную запись?',
			'Подтвердить': 'Подтвердить',
			'Отмена': 'Отмена',
			'Персональные данные': 'Персональные данные',
			'Управление персональными данными': 'Управление персональными данными'
		},
		uk: {
			'Настройки приватности': 'Налаштування приватності',
			'Разрешить покупателям связываться со мной через чат': 'Дозволити покупцям зв’язуватись зі мною через чат',
			'Получать рассылки про обновление, акции, конкурсы': 'Отримувати розсилки про оновлення, акції, конкурси',
			'Управление страницей': 'Керування сторінкою',
			'Вы можете удалить свою учетную запись. Восстановить доступ к своей странице можно в любой момент.': 'Ви можете видалити свій обліковий запис. Відновити доступ до сторінки можна в будь-який момент.',
			'Удалить': 'Видалити',
			'Вы действительно хотите удалить свою учетную запись?': 'Ви дійсно хочете видалити свій обліковий запис?',
			'Подтвердить': 'Підтвердити',
			'Отмена': 'Відміна',
			'Персональные данные': 'Персональні дані',
			'Управление персональными данными': 'Управління персональними даними'
		}
	}
};
