export default {
	messages: {
		ru: {
			'Покупатели не могут связаться с вами в чате!': 'Покупатели не могут связаться с вами в чате!',
			'Нам пришлось выключить чат, поскольку вы уже неделю не отвечаете потенциальным покупателям. Сейчас они могут связаться с вами только через телефонный звонок': 'Нам пришлось выключить чат, поскольку вы уже неделю не отвечаете потенциальным покупателям. Сейчас они могут связаться с вами только через телефонный звонок',

		},
		uk: {
			'Покупатели не могут связаться с вами в чате!': 'Покупці не можуть зв\'язатись із вами в чаті!',
			'Нам пришлось выключить чат, поскольку вы уже неделю не отвечаете потенциальным покупателям. Сейчас они могут связаться с вами только через телефонный звонок': 'Нам довелось вимкнути чат, оскільки ви вже тиждень не відповідаєте потенційним покупцям. Зараз вони можуть зв’язатись із вами тільки через телефонний дзвінок',
		}
	}
};
