import {mapGetters, mapActions} from 'vuex';
import axios from 'axios';
const segmentParser = require('../../../helpers/segmentParser');

export default {
	i18n: require('./i18n').default,
	components: {
		'vue-chat-alert': require('../chatAlert/index.vue').default,
		'rating': require('../Profile/Rating/index.vue').default,
	},
	data: function () {
		return {
			askAgain: false,
			contactViaEmail: false,
			subscribeUserToNews: false,
			disableChatWorker: false,
			iframeHeight: 279
		};
	},
	name: 'Customization',
	computed: {
		...mapGetters({
			langId: 'lang/id',
			tags: 'cabinet/tags/tags',
			segment: 'cabinet/segment',
			_auth: 'cabinet/userInfo/_auth',
			counters: 'cabinet/leftBar/counters'
		}),
		activeAdv() {
			return (this.counters && this.counters.MyAdvertisementsPublished) ? this.counters.MyAdvertisementsPublished.count : 0;
		},
	},
	mounted() {
		window.addEventListener('message', this.receiveMessage, false);
		this.iframeHeight = this.isMobile ? 381 : 279;
		this.setNewAccountStatus({noCache: true});
	},
	methods: {
		...mapActions({
			setNewAccountStatus: 'cabinet/tags/tags',
		}),
		receiveMessage(event) {
			if (event && event.data && event.origin.match('ria.com') && typeof event.data === 'string') {
				const data = JSON.parse(event.data, true);
				if (data && data.from !== 'chat') {
					/* Змінилась висота блока */
					if (data.height && Number(data.height)) {
						this.iframeHeight = Number(data.height + 10);
					}
				}
			}
		},
		deleteAccount() {
			this.$root.$emit('appContentAddClass', 'content_load');
			axios.get('/ph/mymenu/deleteaccount/').then((resp) => {
				this.$root.$emit('appContentAddClass', '');
				if (resp.status === 200) {
					this.setNewAccountStatus({noCache: true});
				}
			}).catch((e) => {
				console.error('/ph/mymenu/deleteaccount/', e.message);
			});
		},
		sendSlonChat(statusChat) {
			this._slonik({
				project_id: 1,
				event_id: 696,
				status_chat: statusChat,
				screentype: this.isDesktop ? 1 : 2,
				user_id: this._auth.userId,
				owner_id: this._auth.userId,
			});
		},
		toggleContactViaEmail: function () {
			this.sendSlonChat(Number(!this.contactViaEmail));
			axios({
				url: `/users/userParams/contactViaEmail/${Number(!this.contactViaEmail)}/`,
				method: 'PUT',
			}).catch((e) => {
				console.error('/users/userParams/contactViaEmail/', e.message);
			});
		},
		toggleSubscribeUserToNews: function () {
			axios({
				url: `/users/userParams/subscribeUserToNews/${Number(!this.subscribeUserToNews)}/`,
				method: 'PUT'
			}).catch((e) => {
				console.error('/users/userParams/subscribeUserToNews/', e.message);
			});
		},
	},
	watch: {
		tags: function () {
			this.contactViaEmail = this.tags.contactViaEmail;
			this.disableChatWorker = this.tags.disableChatWorker || false;
			this.subscribeUserToNews = this.tags.newsLetters && this.tags.newsLetters.isActive;
		},
		isMobile(newVal) {
			this.iframeHeight = newVal ? 381 : 279;
		}
	}
};
